'use client';

import { FacebookWhite } from '@ahamove/icons';
import { cn, xorDecrypt } from '@ahm/common-helpers';
import { useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect } from 'react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { publicEnv } from '@/config/public-env.config.mjs';

declare global {
  const FB: any;
  interface Window {
    fbAsyncInit: () => void;
  }
  interface Element {
    src: string;
  }
}

type Props = {
  title?: string;
  className?: string;
};

export default function FbLoginForBusinessBtn({ className, title }: Props) {
  const tAhafood = useTranslations('ahafood');

  const searchParams = useSearchParams();
  const errorMessage = searchParams.get('error_message');
  const session = searchParams.get('session');

  function clearDataFromUrl(fields: string[]) {
    try {
      const url = new URL(window.location.href);
      fields.forEach((field) => url.searchParams.delete(field));
      window.history.replaceState({}, '', url.toString());
    } catch (error) {
      console.error('Failed to clear error code and message from url', error);
    }
  }

  useEffect(() => {
    if (session) {
      const decryptedData = xorDecrypt<{ total_page: number }>(
        session,
        publicEnv.NEXT_PUBLIC_XOR_KEY
      );

      if (decryptedData?.total_page) {
        toast.success('Liên kết Facebook thành công', {
          description: `Bạn đã liên kết thành công ${decryptedData.total_page} trang Facebook`,
        });
      }
      clearDataFromUrl(['session']);
    }

    if (errorMessage) {
      toast.error('Liên kết Facebook không thành công', {
        description: errorMessage ?? undefined,
      });

      clearDataFromUrl(['error_code', 'error_message']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFBLogin = useCallback(() => {
    // const fbLoginUrl = `https://www.facebook.com/${publicEnv.NEXT_PUBLIC_FACEBOOK_API_VERSION}/dialog/oauth?app_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&client_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&config_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_BUSINESS_CONFIG_ID}&fallback_redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}&locale=${locale}&override_default_response_type=true&redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}/api/auth/callback/facebook&response_type=code`;

    const scopes = [
      'email',
      '@/public_profile',
      // 'pages_manage_ads',
      // 'pages_manage_metadata',
      // 'pages_read_engagement',
      // 'pages_read_user_content',
      // 'pages_manage_posts',
      // 'pages_manage_engagement',
      // 'page_events',
      // 'business_management',
      'pages_messaging',
      'pages_manage_metadata',
      'pages_show_list',
    ];

    const fbLoginUrl = `https://www.facebook.com/${publicEnv.NEXT_PUBLIC_FACEBOOK_API_VERSION}/dialog/oauth?client_id=${publicEnv.NEXT_PUBLIC_FACEBOOK_APP_ID}&redirect_uri=${publicEnv.NEXT_PUBLIC_APP_URI}/api/auth/callback/facebook&scope=${scopes.join(',')}&response_type=code&auth_type=rerequest&state={}`;
    // open Facebook login url using next/router
    window.open(fbLoginUrl, '_self');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      className={cn(
        'h-[60px] rounded bg-primary-50 px-12 py-4 text-xl font-bold text-white',
        className
      )}
      onClick={handleFBLogin}
    >
      <FacebookWhite className="mr-2 size-6" />
      {title ? title : tAhafood('linkFacebookNow')}
    </Button>
  );
}
